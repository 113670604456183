.carousel-inner {
  width: 100%;
}
.product-page-carousel {
  display: flex;
  align-items: center;
}

.product-page-carousel .carousel-inner {
  padding: 3rem;
}

.carousel {
  max-width: 700px !important;
  min-width: 200px;
  padding: 1rem;
}

.carousel .carousel-control-prev-icon,
.carousel .carousel-control-next-icon {
  width: 25px;
  height: 25px;
}

.d-block {
  margin: auto;
}

.top-product-title {
  position: relative;
  color: #ffffff;
  font-size: min(3.5vw, 30px);
  letter-spacing: 0.6px;
  padding-left: 15px;
  font-weight: 500;
  padding-right: 15px;
}

.top-product-img {
  max-width: 400px;
  max-height: 400px;
  margin-bottom: 1rem;
}

.top-product-title::before {
  background-color: black;
  content: '';
  width: 100%;
  height: 1.5em;
  position: absolute;
  z-index: -1;
  filter: url(#marker-shape);
  left: -0.1em;
  /* top: -0.2em; */
  padding: 0 0.3em;
}

.top-product-title-container {
  margin: 0 0 2rem 0;
  text-align: center;
}

.carousel-link-to-product {
  text-decoration: none;
}

.product-page-carousel > .carousel-inner {
  height: fit-content;
}

.product-page-carousel > .carousel-inner > .active {
  display: flex;
}
