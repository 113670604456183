.my-orders-container {
  background-color: #f3f3f3;
  margin-top: 80px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.my-orders-title {
  padding: 15px 0;
}

.main-card > .card-body > .card > .card-img {
  height: auto;
}

.main-card > .card-body > .card-title:nth-child(1) {
  margin-bottom: 2rem;
}

.order-summary {
  margin: 3rem 0;
  display: flex;
  justify-content: space-between;
  border-top: solid 1px rgb(216, 211, 211);
}
.order-summary > * {
  margin-top: 3rem;
}

.main-card .shipping-details-card > .card-body {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .order-summary {
    flex-direction: column;
    align-items: center;
  }
}
