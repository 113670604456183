.pw-changed-container {
  background-color: #f3f3f3;
  margin-top: 80px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.pw-changed-title {
  padding: 15px 0;
}

.pw-changed-btn {
  width: 100%;
}
