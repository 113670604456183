.change-address {
  color: blue !important;
  text-decoration: underline !important;
}

.shipping-details-card {
  margin-bottom: 4rem;
}

.missing-shipping-details {
  color: red !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.accordion {
  margin-bottom: 1rem;
}

.accordion-button-order-confirmation {
  padding-left: 0;
  padding-right: 0;
}

.user-guest-option-border {
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  color: #343a40;
}

.user-guest-option-border:before,
.user-guest-option-border:after {
  content: '';
  flex: 1 1;
  border-bottom: 2px solid #ced4da;
  margin: auto;
}

.user-guest-option-border > span {
  margin: 5px;
}
