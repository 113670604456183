.login-container {
  background-color: #f3f3f3;
  margin-top: 80px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.login-title {
  padding: 15px 0;
}

.login-error {
  color: red;
}

.login-btn {
  width: 100%;
}

.login-options {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.login-options > * {
  color: blue !important;
  text-decoration: underline !important;
}
.login-container-center {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verification-link {
  color: blue !important;
  font-weight: bold;
}
