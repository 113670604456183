header {
  margin-bottom: 55px;
}

.navbar {
  background-color: #8dbfc8;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.navbar > .container-fluid {
  padding: 5px;
}
.search-bar {
  text-indent: 10px;
  border-radius: 15px;
  background: url('https://img.icons8.com/material/15/undefined/search--v1.png')
    no-repeat scroll 7px white;
  padding-left: 22px;
  border: 1.5px solid #0000006b;
}

.icons-search {
  display: flex;
}

.collapse-search-bar {
  width: 100%;
  margin-right: 0;
}

.fa,
.far {
  cursor: pointer;
  font-size: 28px !important;
  margin-left: 12px;
}

.navbar-toggler {
  margin-top: 4px;
  margin-left: 2px;
}

.fa-heart {
  color: rgb(224, 83, 83);
}

.navbar-toggler {
  color: black;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navbar-brand > img {
  width: 55px;
}

.navbar-collapse {
  flex-grow: 0 !important;
}

label {
  width: 100%;
}

.search-container,
.collapse-search-container {
  display: inline-block;
  position: relative;
}
.search-slider .nav-slider-name {
  color: #212529;
  cursor: pointer;
}

.search-slider > .col:hover {
  background-color: #3e3f3f2c;
  border-radius: 6px;
}

.search-slider {
  display: block;
  position: absolute;
  border: 1px solid #a4a5a5;
  background: #f3f3f3;
  border-radius: 10px;
}

.search-slider > * {
  cursor: pointer;
  display: block;
  padding: 0.3rem;
}

.navbar-toggler {
  border: 0 !important;
}

.fa-shopping-cart {
  color: #212529;
}

.cart-amount-popon {
  display: -webkit-inline-box;
  position: absolute;
  right: 55px;
  top: 5px;
}

.cart-amount-popon > * {
  width: 1.3rem;
  height: 1.3rem;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 15px;
  align-content: center;
  font-weight: 500;
  justify-content: center;
}

.search-result-product-image-container {
  max-width: 70px;
  display: flex;
}

.search-result-product-image-container > img {
  width: 100%;
}

.search-result {
  margin-top: 5px;
  margin-bottom: 5px;
}

@media screen and (min-width: 768px) {
  .collapse-search-container {
    display: none;
  }
}
