@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');

.contact-us-container {
  max-width: 500px;
  font-family: Mukta, sans-serif;
}

.contact-us-title {
  text-align: center;
  letter-spacing: 0.6px;
  font-weight: bold;
  text-decoration: none;
  box-shadow: inset 0 -1px 0 #11141638;
  padding: 5px 0;
  margin-bottom: 1rem;
}

.contact-us-subtitle {
  text-align: center;
  padding: 0.5rem 0;
}

.contact-us {
  margin-bottom: 3rem !important;
}

.contact-us > .contact-us-container {
  justify-content: center;
}

.contact-us > .contact-us-container > .row {
  justify-content: center;
}

.contact-us > .contact-us-container > .row > .contact-us-button {
  background-color: rgb(0 0 0 / 73%) !important;
  color: white !important;
  margin: 1rem 0;
  max-width: 300px;
}

#contactUs {
  scroll-margin-top: 70px;
}
