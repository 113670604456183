.shopping-cart-container {
  background-color: #f3f3f3;
  margin-top: 80px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.shopping-cart-title {
  padding: 15px 0;
}

.checkout-button {
  margin-top: 10px;
}

.shopping-cart-container-center {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .shopping-cart-data {
    flex-direction: column-reverse;
  }
  .checkout-card {
    margin-bottom: 30px;
  }
}
