.update-password-container {
  background-color: #f3f3f3;
  margin-top: 80px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.update-password-title {
  padding: 15px 0;
}

.update-password-btn {
  margin: 1rem 0;
  width: 100%;
}

.input-error {
  color: red;
  font-size: 14px;
}
