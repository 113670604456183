@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');

.top-products-text {
  font-weight: bold;
  font-family: Mukta, sans-serif;
  letter-spacing: 0.6px;
  text-decoration: none;
  box-shadow: inset 0 -1px 0 #11141638;
  padding: 5px 0;

  margin: 1rem 0;
}

.top-products {
  font-family: Mukta, sans-serif;
  text-align: center;
  letter-spacing: 0.6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
