html {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0 0 56px;
  min-height: 85vh;
}

footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.main-content {
  display: flex;
  justify-content: center;
}

.loading-gif {
  display: flex;
  justify-content: center;
}

.pages {
  font-size: 15px;
  margin: 3rem 0 1rem 0;
  text-align: center;
}

.pages > .row {
  justify-content: center;
}

.pages > .row > .col > * {
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.pages > .row > .col {
  max-width: 67px;
  padding: 0;
}
.chosen-page {
  font-weight: bold;
  text-decoration: underline !important;
  text-decoration-style: solid;
}

.container-center {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-content-container {
  width: 100%;
}

.zigzag-content {
  --mask: conic-gradient(
        from 135deg at top,
        #0000,
        #000 1deg 89deg,
        #0000 90deg
      )
      top/40.00px 51% repeat-x,
    conic-gradient(from -45deg at bottom, #0000, #000 1deg 89deg, #0000 90deg)
      bottom/40.00px 51% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  background: #8dbdc873;
  padding: 2.5rem;
  max-width: 100%;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
}

.center-text {
  text-align: center;
}

[data-aos] {
  pointer-events: none !important;
  transform-style: preserve-3d !important;
}
.aos-animate {
  pointer-events: auto !important;
  transform-style: preserve-3d !important;
}
.aos-init[data-aos][data-aos].aos-animate {
  transform: unset !important;
  transform-style: preserve-3d !important;
}

/* .aos-fix[data-aos][data-aos].aos-animate {
  transform: unset;
}

.aos-fix[data-aos][data-aos].aos-animate {
  transform: initial;
} */
