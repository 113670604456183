.card {
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  /* text-align: justify; */
}

.card-body {
  padding: 2rem 2.5rem !important;
  display: flex;
  flex-direction: column;
}

.form-select {
  width: auto !important;
}

.quantity {
  display: flex;
  justify-content: space-between;
}

.btn {
  text-transform: none;
  background-color: #8dbfc8 !important;
  color: black !important;
  border: 0 !important;
  margin: 0;
}

.buttons {
  margin-top: auto;

  justify-content: space-between;
  flex-wrap: wrap;
}

.product {
  display: flex;
  flex-direction: column;
}

.product > .row > .carousel {
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .buttons > * {
    margin: 5px 0;
  }
  .buttons {
    margin-top: auto;
  }
}

.react-numeric-input {
  max-width: 6rem;
  min-width: 5rem;
}

.price {
  margin-top: 1.5rem !important;
}

.price > .old-price-product-page {
  text-decoration: line-through;
  font-weight: 300;
}

.price > .discount-price-product-page {
  color: #d01345;
}

.on-stock {
  color: green;
}

.product-button {
  width: 100%;
}
.out-of-stock {
  color: red;
}

.product-wishlist {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .buttons,
  .product > .row {
    flex-direction: column;
  }
}
