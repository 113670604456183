.fab-container > div {
  width: fit-content;
}

.fab-container > .row {
  flex-direction: column;
  background-color: #212529bf;
  border-radius: 8px 0px 0px 8px;
  padding: 0.5rem 0;
  /* box-shadow: -1px 0px 19px black; */
}

.fab-link {
  text-decoration: none;
  margin: 0.3rem 0;
}

.tab {
  cursor: pointer;
  text-align: center;
  color: white;
  font-size: 22px;
}

.close-tab {
  margin-bottom: 0.7rem;
}

.fab-container {
  position: fixed !important;
  right: 13px;
  top: 50%;
  z-index: 100;
}

.fab-cart {
  background-color: #8dbfc8 !important;
}

.fab-icon {
  font-size: 22px;
}

.fab-cart-amount-popon {
  display: -webkit-inline-box;
  position: absolute;
  right: 11px;
  top: 3px;
}

.fab-cart-amount-popon > * {
  width: 1.3rem;
  height: 1.3rem;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 15px;
  align-content: center;
  font-weight: 500;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .fab-container {
    display: none;
  }
}
