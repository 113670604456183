@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');

.error-page {
  margin: 0 auto;
  max-width: 2000px;
  font-family: 'Mukta', sans-serif;
  letter-spacing: 0.6px;
  padding: 2rem;
}

.error-page-image {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.error-page-image > img {
  width: 100%;
  max-width: 800px;
}

.error-page-data > .row {
  flex-direction: column;
}

.error-page-title {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: min(max(5vw, 50px), 80px);
}

.error-page-description {
  color: rgba(0, 0, 0, 0.741);
  font-size: clamp(17px, 2vw, 20px);
}

.error-page-button-col {
  margin: 1rem 0;
}

.error-page-button {
  letter-spacing: 0.5px;
  padding: 0.5rem 1rem;
  margin-right: 1.5rem;
  font-weight: bold;
}

@media only screen and (max-width: 390px) {
  .error-page-button-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .error-page-button-col > .error-page-button:last-child {
    margin-top: 1rem;
  }
}
