@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');

.who-are-we {
  font-family: Mukta, sans-serif;
  text-align: center;
  letter-spacing: 0.6px;
}
.who-are-we-title {
  font-weight: bold;
  text-decoration: none;
  box-shadow: inset 0 -1px 0 #11141638;
  padding: 5px 0;
  margin-bottom: 1rem;
}

.zigzag-content > .who-are-we-container {
  max-width: 1000px;
}

#aboutUs {
  scroll-margin-top: 70px;
}
