.card {
  flex-direction: row;

  margin-bottom: 1rem;
}

.card-title {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.product-card > .card-img {
  cursor: pointer;
}

.order-review-product-units {
  font-size: 20px !important;
}

.card-img {
  /* width: 30%; */
  max-height: 150px;
  max-width: 100px;
  align-self: center;
}
.score {
  background-color: #008bff;
  border-radius: 5px;
  padding: 0 5px;
  font-weight: bold;
}

.svg-inline--fa {
  font-size: 25px;
  margin-right: 5px;
  cursor: pointer;
}

.date {
  display: block;
}

.card-text {
  color: #000000bd;
}

.card-buttons {
  justify-content: space-between;
  margin-top: 0.5rem;
}
.card-buttons > * {
  margin-bottom: 1rem;
}

.card-buttons > a {
  text-align: center;
}

.card-button {
  margin: 0 15px 5px 0;
}

.react-numeric-input > input {
  width: 100%;
}

.choose-button {
  display: flex;
  padding-right: 0.3rem;
  align-content: center;
  flex-wrap: wrap;
}

.choose-button > input {
  position: unset;
  margin-left: 10px;
}

.cart-text {
  display: flex;
  justify-content: space-between;
}

.on-stock {
  color: green !important;
  float: right;
}

.out-of-stock {
  color: red !important;
  text-align: center;
}

.old-price {
  color: black;
  text-decoration: line-through;
  font-weight: 300;
}

.stock-container {
  justify-content: flex-end;
}
.stock-container > * {
  width: auto;
}

.amount-section {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.price-section-category {
  text-align: center;
  color: black !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.discount-price {
  color: #d01345 !important;
}

.price-section-review {
  color: black !important;
  font-weight: bold !important;
  font-size: 20px !important;
  float: right;
}

.price-per-unit {
  font-size: 15px;
  font-weight: lighter;
}

.order-confirmation-details-container {
  margin-top: 1rem;
}

.order-product-details {
  height: 100%;
  flex-wrap: wrap;
  align-content: center;
}

.order-product-details > * {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.order-product-total-price {
  font-weight: bold !important;
}

.center-product-image {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  min-height: 150px;
}

.wishlist-product-link {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  .order-product-image-column,
  .order-product-details-column {
    justify-content: center;
    text-align: center;
  }
}
