@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');

.sidebar {
  font-family: 'Mukta', sans-serif;
  letter-spacing: 0.6px;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  min-height: 100vh !important;
  z-index: 100;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.category-nav-item:hover {
  background-color: rgba(0, 0, 0, 0.248);
}
#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.category-aside {
  min-width: 200px;
}

.col-md-12 {
  padding-right: 0;
}

.heading > .nav-link {
  background-color: #e3e6e9;
  padding: 20px;
  font-weight: bold;
  border-bottom: 1px solid #a5a5a5;
  font-size: 20px;
  text-align: center;
}

.category-link-container > img {
  margin-right: 8px;
}

.sidebar > a:nth-child(4) > div > div > img {
  transform: scaleX(-1);
}

.category-link {
  text-decoration: none;
  color: black;
}

.category-link-container {
  padding: 0.5rem 1rem;
}

@media only screen and (max-width: 1000px) {
  .category-aside {
    display: none;
  }
}
