.my-account-container {
  background-color: #f3f3f3;
  margin-top: 80px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.my-account-title {
  padding: 15px 0;
}

.my-account-btn {
  margin-top: 1rem;
  width: 100%;
}

.my-account-row > * {
  margin-bottom: 1rem;
}
.my-account-row > * > .card-link {
  color: blue !important;
  text-decoration: underline !important;
}

.no-details-provided {
  color: red !important;
}

.input-error-user-details {
  color: red;
  font-size: 13px;
}

@media only screen and (max-width: 575px) {
  .my-account-row {
    text-align: center;
  }
}
