@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');

.companies-logos-slider-container {
  padding: 40px;
  max-width: 800px;
  margin: 1rem auto;
}

.companies-logos-slider-container > h1 {
  text-align: center;
  letter-spacing: 0.6px;
  font-weight: bold;
  font-family: Mukta, sans-serif;
  text-decoration: none;
  box-shadow: inset 0 -1px 0 #11141638;
  padding: 5px 0;
  margin-bottom: 2rem;
}

.slick-slide {
  margin: auto;
}

.slick-prev:before {
  color: rgba(0, 0, 0, 0.832);
}

.slick-next:before {
  color: rgba(0, 0, 0, 0.832);
}

.slick-track > * > * > * > img {
  max-width: 100%;
}

.slick-track > * > * {
  margin: 0 1rem;
}

.slick-slide {
  width: auto;
}
