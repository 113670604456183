.categories-container {
  background-color: #f3f3f3;
  padding: 0 2rem;
  padding-bottom: 15px;
}

.categories-title {
  padding: 15px 0;
}

.product-card > .card-body > .card-buttons > * {
  margin-bottom: 10px;
}

.categories-container .product-card {
  flex-direction: column;
}

.categories-container {
  width: 100%;
}

.categories-container .product-card > .card-img {
  width: 30%;
  margin-top: 1rem;
}

.category-name-and-sort {
  display: flex;
  justify-content: space-between;
}

.category-name-and-sort > select {
  height: 2.5rem;
  align-self: center;
}

@media screen and (max-width: 600px) {
  .category-name-and-sort {
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
  }
}

.card-container {
  display: flex;
}

.card-container > * {
  flex: auto;
}

@media only screen and (max-width: 1000px) {
  .category-main-content {
    flex-direction: column;
    align-items: center;
  }
}
