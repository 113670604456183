.register-container {
  background-color: #f3f3f3;
  margin-top: 80px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.register-title {
  padding: 15px 0;
}

.alerts {
  color: red;
  font-size: 14px;
}

.register-btn {
  margin-top: 1rem;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
